import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Logo from '../../components/logo'
import { section, bgColor } from '../../styles/styles'


const imagesPath = '../assets'

const President = () => {
    return (
        <section className={`${section}  bg-[#0c1934]`}>
                <div className="wrapper-sm sm:px-6 lg:px-8">
                    <div className="grid items-center grid-cols-1 gap-8  lg:grid-cols-3">
                    <div className="relative pl-0 sm:pl-0 md:px-0">
                        <div className="relative max-w-full w-full sm:max-w-sm sm:p-4 mt-4 mb-10 mx-auto">
                                <StaticImage
                                className="ml-auto"
                                    src={`../${imagesPath}/president.jpg`}
                                    alt="Noureddine Karimi"
                                />
                            <div className="absolute bottom-0 left-0  w-full text-center sm:-bottom-4 sm:-left-24 ">
                                <div className="bg-primary">
                                    <div className="px-6 py-4">
                                        <p className='text-white text-lg tracking-widest m-0 font-semibold'>Noureddine Karimi</p>
                                        <p className='m-0 text-lg tracking-wider font-light'>Président KNR Events</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <div className='sm:px-10 lg:col-span-2'>
                            <div className='relative'>
                            <h2 className="title text-primary sm:text-4xl lg:text-5xl">
                                    Mot du Président
                            </h2>
                            </div>
                        <div className="lg:col-span-3 py-4 sm:pr-6" >
                                <p>
                                    Chers sportifs et sportives,
                                </p>
                                <p>
                                    Passant la période de pandémie du Covid 19, la vie normale est de retour. KNR EVENTS se positionne en avant-garde pour la confection d’événements sportifs répondant aux attentes de tous les adeptes des sports d’endurance. Dans cette optique, notre dévouement nous incite à innover des événements sportivo-touristiques, alliant effort physique, loisir et plaisir de découvrir la sublime région du Souss.
                                </p>
                            <p className="text-right font-light text-base">
                                    Sportivement,
                                    <span className='block'>
                                        Noureddine Karimi.
                                    </span>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

    )
}

export default President