import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby'
import { section, btnOutline } from '../../styles/styles';
const imagesPath = '../assets'
const EventSection = () => {
    return (
        <>
            <section className={`${section} bg-white py-0 sm:pt-20`} id='raid-amitie'>

                <h2 className="text-center title text-primary sm:text-4xl lg:text-5xl mb-20">
                    Nos évènements
                    <span className="relative mt-3 text-3xl sm:text-4xl block text-[#0c1934]">Conception & organisation</span>
                </h2>
                <div className="wrapper-sm sm:px-6 lg:px-8">
                    <div className="grid items-center grid-cols-1 gap-10 lg:grid-cols-3">
                        <div className="lg:col-span-2 lg:px-10">
                            <div className='relative'>
                                <h2 className="title relative z-10 font-bold text-[#0c1934] sm:text-3xl lg:text-4xl">
                                    Raid de l'amitié
                                </h2>

                            </div>

                            <p className="dark">
                                L’année 2022 restera à jamais marquée dans l’esprit du staff d’organisation de KNR EVENTS, avec le succès inégalé de la première édition du RAID DE L’AMITIE VTT tenue en mars, rassemblant une centaine de vététistes Marocains et étrangers qui ont vécu une aventure inédite et tissé de nouvelles rencontres et amitiés durant deux étapes de 140 km de Cross-country, reliant Timlilt à Aourir en passant par la vallée d’Imouzzar-Idaoutanane et arrivant en apothéose à la côte atlantique.
                            </p>
                            <p className="dark">
                                Par son originalité et succès, le RAID DE L’AMITIE VTT est un rendez-vous unique à ne manquer sous aucun prétexte, afin d’allier la pratique du VTT et la découverte de la nature magique du Souss.

                            </p>

                        </div>

                        <div className='h-full bg-white'>
                            <iframe className='w-full aspect-video lg:aspect-square' src="https://www.youtube.com/embed/H2SMpG4TLys"
                                title="Raid N'Timlilt" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`${section}`} id='trail-ntimlilt'>
                <div className="wrapper-sm sm:px-6 lg:px-8">
                    <div className="grid items-center grid-cols-1 gap-6 lg:grid-cols-3">
                        <div className='bg-white relative w-full h-auto'>
                            <iframe className='aspect-video lg:aspect-square w-full h-auto' src="https://www.youtube.com/embed/NrE0Apjxgdo"
                                title="Raid N'Timlilt" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>

                        <div className="lg:col-span-2 lg:px-10">
                            <div className='relative'>
                                <h2 className="title relative z-10 font-bold text-[#0c1934] sm:text-3xl lg:text-4xl">
                                    Trail N'Timlilt
                                </h2>

                            </div>

                            <p className="dark mt-3">
                                Spécialement conçu pour les adeptes du trail en pleine nature, le 16 octobre 2022, le rêve d’organiser un trail sur les sentiers de Timlilt est devenue une réalité grâce à la volonté et le dévouement de KNR EVENTS en collaboration avec l’association Tamounte pour le développement de Timlilt sous le thème ‘‘Sport, Sourire et Solidarité’’. Un dimanche sportif et familial qui a réuni plus de 200 participants, venants de plusieurs villes marocaines, sur trois épreuves : <span className='font-medium'>Mini Trail enfants 2 km, Randonnée pédestre 6 km et Trail 21 km.</span>
                            </p>
                            <p className="dark mt-3">
                                Des épreuves pleines de concurrence et sportivité ont distingué les vainqueurs hommes et femmes de cette première édition, dans une ambiance festive et joyeuse avec la promesse de tous de revivre cette expérience dans les éditions à venir.
                            </p>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default EventSection;