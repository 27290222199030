import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { section } from '../../styles/styles'


const imagesPath = '../assets'
const About = () => {
    return (
        <section className={`${section}`}>
            <div className="wrapper-sm sm:px-6 lg:px-8">
                <div className="grid items-center grid-cols-1 gap-8 lg:grid-cols-3 mt-4">
                    <div className='lg:col-span-2'>
                        <div className='relative'>
                            <h1 className="title sm:text-4xl lg:text-5xl">
                                Qui sommes nous?
                            </h1>
                        </div>
                        <div className="lg:col-span-2 pt-0 sm:pr-10">
                            <p className="dark">
                                KNR EVENTS est une société spécialisée dans la conception, l’organisation et l’accompagnement dans vos projets sportifs. Fondée en 2019 par des passionnés et pratiquants de sports d’endurance : VTT, CYCLISME, TRAIL ET TRIATHLON.
                            </p>
                            <p className="dark">
                                Forte de son expérience à travers l’organisation du RAID DE L’AMITIE VTT ET DU TRAIL N’TIMLILT EN 2022, deux évènements hautement qualifiés par les participants étant satisfaits au-delà de leurs attentes.
                            </p>
                            <p className="dark">
                                Notre devise reste la créativité, l’originalité et le professionnalisme.
                                <span className='font-semibold'>
                                    Le sport est notre passion avant d’être notre métier.
                                </span>

                            </p>
                        </div>

                    </div>

                    <div className=''>
                        <StaticImage
                            className="w-full"
                            src={`../${imagesPath}/events/about.png`}
                            alt="Qui sommes nous - KNR events"
                        />
                    </div>
                </div>
            </div>


        </section >

    )
}

export default About