import React from 'react'
import Cta from '../components/cta'
import Hero from '../layouts/hero'
import About from '../layouts/knr-events/about-knr'
import EventSection from '../layouts/knr-events/event-knr'
import President from '../layouts/knr-events/president'
import Layout from '../layouts/layout'
import Seo from '../components/seo'

const NosEvenements = () => {
    return (
        <Layout>
            <About />
            <President />
            <EventSection />
            <Cta />
        </Layout>
    )
}

export const Head = () => <Seo title="KNR Events" />

export default NosEvenements